<template>
  <div class="page-div">
    <page-navigation-bar title="新建收货地址" />
    <div class="page-content-div">
      <div class="item-div">
        <label>姓名</label>
        <label class="input-label"><input type="text" v-model="addressee" maxlength="20" placeholder="请输入联系人姓名"></label>
      </div>
      <div class="item-div">
        <label>手机号</label>
        <label class="input-label"><input type="number" v-model="phone" placeholder="请输入您的手机号"></label>
      </div>
      <div class="address-div">
        <div class="address-title-div">收货地址</div>
        <div class="address-textarea-div">
          <label><textarea v-model="address" maxlength="50" placeholder="如xxx省xxx市xxx区xxx"></textarea></label>
        </div>
      </div>
      <div class="tips-div">
        <div>温馨提示</div>
        <div>请认证填写收货地址，如因收货地址信息有误导致不能正常收货，自行负责！</div>
      </div>
    </div>
    <div class="btn-div">
      <input type="button" value="保存" @click="onAddAddress">
    </div>
  </div>
</template>

<script>
import PageNavigationBar from "../components/PageNavigationBar";
import {postAddressAdd } from "../common/api";

export default {
  name: "AddressAdd",
  components: { PageNavigationBar },
  data() {
    return {
      addressee: "",
      phone: "",
      address: ""
    }
  },
  methods: {
    onAddAddress() {
      if(this.addressee.length < 1){
        this.$layer.msg("请输入姓名");
        return;
      }
      if(!/^1\d{10}$/.test(this.phone)){
        this.$layer.msg("手机号错误，请重新输入");
        return;
      }
      if(this.address.length < 1){
        this.$layer.msg("请输入收货地址");
        return;
      }

      postAddressAdd({
        name: this.addressee,
        phone: this.phone,
        address: this.address
      }).then(data => {
        this.$layer.msg("添加成功");
        this.$router.back();
      }).catch(error => {
        this.isLoading = false;
        if(Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")){
          this.$layer.msg(error.data.msg);
        }else{
          this.$layer.msg("添加收货地址失败，请稍后重试");
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.page-div {
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  .page-content-div {
    width: 95%;
    margin: 0.1rem auto 0 auto;
    border-radius: 0.05rem;
    background-color: #FFFFFF;
    .item-div {
      width: 96%;
      margin: 0 auto;
      padding: 0.1rem 0;
      border-bottom: 2px solid #F8F8F8;
      display: flex;
      justify-content: space-between;
      .input-label {
        flex: 1;
        margin-left: 0.1rem;
        input {
          width: 100%;
          text-align: right;
          border: none;
        }
      }
    }
    .address-div {
      width: 96%;
      margin: 0 auto;
      .address-title-div {
        padding: 0.1rem 0;
      }
      .address-textarea-div {
        width: 100%;
        textarea {
          width: calc(100% - 0.1rem);
          height: 0.7rem;
          resize: none;
          border: none;
          border-radius: 0.03rem;
          background-color: #F8F8F8;
          padding: 0.05rem;
        }
      }
    }
    .tips-div {
      width: 90%;
      margin: 0.1rem auto 0 auto;
      font-size: 0.13rem;
      padding-bottom: 0.3rem;
    }
  }
  .btn-div {
    width: 100%;
    margin-top: 0.3rem;
    text-align: center;
    input {
      width: 3rem;
      height: 0.4rem;
      border-radius: 0.2rem;
      border: none;
      color: #FFFFFF;
      background-color: #DD4F42;
    }
  }
}
</style>